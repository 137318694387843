import { debounce } from '@wppopen/components-library'
import { useEffect, useState, useDeferredValue, useMemo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export type ApiSearchReturn = [searchUrlParamName: string, (value: string) => void]

export const useApiSearch = (searchParamName: string, isFetching?: boolean): ApiSearchReturn => {
  const [searchUrlParamName] = useState<string>(`search[${searchParamName}]`)
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchParamValue, setSearchParamValue] = useState<string>(searchParams.get(searchUrlParamName) ?? '')
  const valueToSearch = useDeferredValue(searchParamValue)

  const handleSearchInput = useCallback((value: string) => {
    setSearchParamValue(value)
  }, [])

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 600)
  }, [handleSearchInput])

  useEffect(() => {
    if (valueToSearch.length > 2) {
      setSearchParams(prev => {
        prev.set(searchUrlParamName, valueToSearch)
        return prev
      })
    } else {
      setSearchParams(prev => {
        prev.delete(searchUrlParamName)
        return prev
      })
    }
  }, [valueToSearch, searchUrlParamName, setSearchParams])

  useEffect(() => {
    if (!isFetching && searchParams.get(searchUrlParamName) !== null) {
      const wppSearch = document.getElementById('search-input')
      let time: number | undefined = undefined
      if (wppSearch && wppSearch.shadowRoot) {
        time = window.setTimeout(() => {
          const input = wppSearch.shadowRoot?.querySelector('input')
          if (input) {
            input.focus()
          }
        }, 10)
        return () => {
          if (time) {
            clearTimeout(time)
          }
        }
      }
    }
  }, [searchParams, isFetching, searchUrlParamName])

  return [searchUrlParamName, debouncedSearch]
}
