import { getDevEnv } from 'utils/getDevEnv'

export enum API_TYPES {
  RFI_HELPER_API = 'RFI_HELPER_API',
  MASTER_DATA_API = 'MASTER_DATA_API',
  USERS_API = 'USERS_API',
}

const env = getDevEnv()

const localApi: Record<API_TYPES, string> = {
  RFI_HELPER_API: 'https://rfi-helper-api-ch-hulk.os-dev.io/api',
  // RFI_HELPER_API: 'http://localhost:3001',
  MASTER_DATA_API: 'https://master-data-api-ch-hulk.os-dev.io/api',
  USERS_API: 'https://facade-api-ch-hulk.os-dev.io/api',
}

const otherEnvsApi: Record<API_TYPES, string> = {
  RFI_HELPER_API: 'https://rfi-helper-api-ch-hulk.os-dev.io/api',
  MASTER_DATA_API: 'https://master-data-api-ch-hulk.os-dev.io/api',
  USERS_API: 'https://facade-api-ch-hulk.os-dev.io/api',
}

const config = Object.keys(API_TYPES).reduce(
  (acc, type) => {
    const api = env === 'local' ? localApi[type as API_TYPES] : otherEnvsApi[type as API_TYPES]
    return { ...acc, [type]: api }
  },
  {} as Record<API_TYPES, string>,
)

export default config
