export enum ApiQueryKeys {
  AGENCIES = 'agencies',
  AGENCIES_ALL = 'agencies_all',
  AGENCY_FILES = 'agency_files',
  AGENCY_NAMES = 'agency_names',
  AGENCY_CATEGORIES = 'agency_categories',
  USER_SETTINGS = 'user_settings',
  RFIS = 'rfis',
  RFI = 'rfi',
  MARKETS = 'markets',
  CLIENTS = 'clients',
  RFI_QUESTIONS = 'rfi_questions',
  RFI_QUESTION = 'rfi_question',
  PITCH_TYPES = 'pitch_types',
  SUMMARIES = 'summaries',
  USE_CASE = 'use_case',
  USE_CASES = 'use_cases',
  TASKS_STATUS = 'tasks_status',
  TASKS_STATUSES = 'tasks_statuses',
  REGIONS = 'regions',
  USER_SEARCH = 'user_search',
  RFI_MEMBERS = 'rfi_members',
  USER_ADMIN = 'USER_admin',
  FILE_UPLOAD_URLS = 'file_upload_urls',
  RFIS_META = 'rfis_meta',
  USE_CASES_META = 'use_cases_meta',
}
