import { debounce, InputChangeEventDetail, RichtextChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent, WppRichtextCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconPlus,
  WppIconTrash,
  WppInput,
  WppLabel,
  WppListItem,
  WppModal,
  WppRichtext,
  WppTypography,
} from '@wppopen/components-library-react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useCreateAgencyCategory } from 'api/mutations/agencyCategories/useCreateAgencyCategory'
import { useDeleteAgencyCategory } from 'api/mutations/agencyCategories/useDeleteAgencyCategory'
import { useUpdateAgencyCategory } from 'api/mutations/agencyCategories/useUpdateAgencyCategory'
import { useGetAgencyCategories } from 'api/queries/agency-categories/useGetAgencyCategories'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { Agency } from 'types/agencies/agencies'
import { AgencyCategory, Subcategory } from 'types/agency-categories/agency-categories'

import styles from './AgencyEditKnowledgeBase.module.scss'

const DeleteButton = ({ handleOnClick }: { handleOnClick: () => void }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleDeleteConfirm = () => {
    // open modal to confirm delete
    setModalOpen(true)
  }

  return (
    <>
      <WppModal open={modalOpen}>
        <h3 slot="header">Delete</h3>
        <p slot="body">Are you sure you want to permanently delete this?</p>
        <div slot="actions" className="flex flex-row justify-end gap-4">
          <WppButton variant="secondary" onClick={() => setModalOpen(false)}>
            Cancel
          </WppButton>
          <WppButton
            variant="destructive"
            onClick={() => {
              setModalOpen(false)
              handleOnClick()
            }}
          >
            Delete
          </WppButton>
        </div>
      </WppModal>
      <WppActionButton variant="secondary" onClick={handleDeleteConfirm} className="h-9">
        <WppIconTrash />
      </WppActionButton>
    </>
  )
}

export const AgencyEditKnowledgeBase = ({ agency }: { agency: Agency }) => {
  const { data: categories = [] } = useGetAgencyCategories({ params: agency?.id || '' })
  const [localData, setLocalData] = useState<AgencyCategory[] | []>([])
  const { mutateAsync: createCategory } = useCreateAgencyCategory()
  const { mutateAsync: deleteCategory } = useDeleteAgencyCategory()
  const { mutateAsync: updateCategory } = useUpdateAgencyCategory()
  const [selectedCategory, setSelectedCategory] = useState<AgencyCategory | null>(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null)
  const sideMenuRef = useRef<HTMLDivElement>(null)
  const toast = useToast()

  const handleAddCategory = async () => {
    try {
      const res = await createCategory({
        agencyId: agency.id,
        name: '',
        instruction: null,
        parentCategoryId: null,
      })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })
      if ((res?.data as AgencyCategory)?.id) {
        setSelectedCategory(res?.data as AgencyCategory | null)
        setSelectedSubCategory(null)
      }
    } catch (e) {
      toast.showToast({ message: `Failed to create category : ${e}`, type: 'error' })
    }
  }

  const handleCategoryChange = async (e: WppInputCustomEvent<InputChangeEventDetail>) => {
    if (selectedCategory) {
      const { id = '' } = selectedCategory
      const name = e.detail.value || ''
      setSelectedCategory({ ...selectedCategory, name })
      await updateCategory({
        agencyId: agency.id,
        name,
        instruction: null,
        parentCategoryId: null,
        categoryId: id,
      })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })
    }
  }

  const handleInstructionQueryCache = debounce(async (e, subcategoryId) => {
    const subcategory = selectedCategory?.subcategories.find(subcategory => subcategory.id === subcategoryId)
    const instruction = subcategory?.instruction
    if (subcategory && selectedCategory && instruction) {
      const { name, parentCategoryId } = subcategory
      await updateCategory({
        agencyId: agency.id,
        name,
        instruction,
        parentCategoryId,
        categoryId: subcategoryId,
      }).then(() => {
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })
      })
    }
  }, 500)

  const handleInstructionChange = useCallback(
    (e: WppRichtextCustomEvent<RichtextChangeEventDetail>, subcategoryId: string) => {
      const subcategory = selectedCategory?.subcategories.find(subcategory => subcategory.id === subcategoryId)
      const instruction = e.detail.value
      if (subcategory && selectedCategory && instruction) {
        setSelectedCategory({
          ...selectedCategory,
          subcategories: selectedCategory?.subcategories.map(subcategory => {
            return subcategory.id === subcategoryId ? { ...subcategory, instruction } : subcategory
          }),
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCategory],
  )

  const handleSubcategoryTitleChange = async (
    e: WppInputCustomEvent<InputChangeEventDetail>,
    subcategoryId: string,
  ) => {
    // find subcategory in the selecteCategory
    const subcategory = selectedCategory?.subcategories.find(subcategory => subcategory.id === subcategoryId)
    // update the subcategory name and make sure the localState is also updated
    if (subcategory && selectedCategory) {
      const name = e.detail.value || ''
      const { instruction, parentCategoryId } = subcategory
      setSelectedCategory({
        ...selectedCategory,
        subcategories: selectedCategory?.subcategories.map(subcategory => {
          return subcategory.id === subcategoryId ? { ...subcategory, name } : subcategory
        }),
      })
      /* api call */
      await updateCategory({
        agencyId: agency.id,
        name,
        instruction,
        parentCategoryId,
        categoryId: subcategoryId,
      })
      /* invalidate cache */
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })
    }
  }

  const handleClickCategory = (id: string) => {
    const category = (categories as AgencyCategory[]).find((category: AgencyCategory) => category.id === id)
    setSelectedCategory((category as AgencyCategory) || null)
    setSelectedSubCategory(null)
    // scroll smoothly to the id of this category item in the dom
    setTimeout(() => {
      const categoryElement = document.getElementById('instructions-container') as HTMLElement
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: 'smooth' })
      }
    }, 250)
  }

  const handleDelete = async (id: string) => {
    const isParentCategory = selectedCategory?.id === id
    try {
      await deleteCategory(id)
      if (isParentCategory) {
        // nullify the selected category
        setSelectedCategory(null)
      }
      if (!isParentCategory) {
        const newCategory = { ...selectedCategory } as AgencyCategory
        const subcategories = selectedCategory?.subcategories.filter(subcategory => subcategory.id !== id)
        newCategory.subcategories = subcategories || []
        setSelectedCategory(newCategory)
      }
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })
      setLocalData((categories as AgencyCategory[]).filter((category: AgencyCategory) => category.id !== id))
    } catch (e) {
      toast.showToast({ message: `Failed to delete category : ${e}`, type: 'error' })
    }
  }

  const handleAddSubcategory = async () => {
    if (selectedCategory) {
      //find the parent category id
      const parentCategoryId = selectedCategory.id
      try {
        const res = await createCategory({
          agencyId: agency.id,
          name: '',
          instruction: '',
          parentCategoryId,
        })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_CATEGORIES, agency.id] })

        const id = (res?.data as AgencyCategory)?.id
        const newSubcategory: Subcategory = {
          id,
          name: '',
          instruction: '',
          parentCategoryId,
          agencyId: agency.id,
        }
        const newSelectedCategory = { ...selectedCategory }
        newSelectedCategory.subcategories = [...(selectedCategory?.subcategories || []), newSubcategory]
        setSelectedCategory(newSelectedCategory)
      } catch (e) {
        toast.showToast({ message: `Failed to create subcategory : ${e}`, type: 'error' })
      }
    }
  }

  const handleSubcategoryClick = (parentCategoryId: string, id: string) => {
    // set the selectedcategory to the parentCategory from localData
    const parentCategory = (localData as AgencyCategory[]).find(category => category.id === parentCategoryId)
    setSelectedCategory(parentCategory || null)
    setSelectedSubCategory(id)
    // find the in the dom with the id of the subcategory id and scroll smoothly to it
    // allow time for the dom to render
    setTimeout(() => {
      const subcategory = document.getElementById(id)
      if (subcategory) {
        subcategory.scrollIntoView({ behavior: 'smooth' })
      }
    }, 250)
  }

  const handleHeightOfSideMenu = () => {
    if (sideMenuRef?.current !== null) {
      sideMenuRef.current.style.height = `${window.innerHeight - (sideMenuRef.current.getBoundingClientRect().y + 100)}px`
    }
  }
  useEffect(() => {
    handleHeightOfSideMenu()
    window.addEventListener('resize', handleHeightOfSideMenu)
    window.addEventListener('scroll', handleHeightOfSideMenu)
  }, [])

  useEffect(() => {
    if (Array.isArray(categories) && categories?.length) {
      setLocalData(categories as AgencyCategory[])
    }
  }, [categories])

  return (
    <div className="flex pt-4" data-testid="AgencyEditKB-container">
      <aside className="sticky top-16 overflow-y-scroll basis-1/4" ref={sideMenuRef}>
        <WppCard variant="secondary" className="side basis-1/4" class={styles.sidebarCard}>
          <WppTypography type="s-strong">Knowledge Base</WppTypography>
          <ul>
            {(localData as AgencyCategory[]).map((category: AgencyCategory) => {
              return (
                <div key={`list-${category?.id}`}>
                  <WppListItem
                    checked={selectedCategory?.id === category.id && !selectedSubCategory}
                    onClick={() => handleClickCategory(category.id)}
                    key={category.id}
                  >
                    <div slot="label">
                      <div>{category.name || 'Untitled Category'}</div>
                    </div>
                  </WppListItem>
                  {category?.subcategories
                    ? category.subcategories.map(subcategory => {
                        return (
                          <WppListItem
                            checked={selectedSubCategory === subcategory.id}
                            onClick={() => handleSubcategoryClick(subcategory.parentCategoryId, subcategory.id)}
                            key={subcategory.id}
                            className="indent-4"
                          >
                            <p slot="label">{subcategory.name || 'Untitled Subcategory'}</p>
                          </WppListItem>
                        )
                      })
                    : null}
                </div>
              )
            })}
          </ul>
        </WppCard>
        <WppListItem onWppChangeListItem={handleAddCategory} className={styles.newCategoryListItem}>
          <span slot="label">Add New Category</span>
          <WppIconPlus slot="left" />
        </WppListItem>
      </aside>
      <div
        className="content flex flex-col gap-4 basis-3/4 p-3 bg-[#FFFFFF] ml-[15px] rounded-lg"
        id="instructions-container"
      >
        <WppButton onClick={handleAddCategory} className="ml-auto">
          <div className="flex">
            <WppIconPlus color="white" slot="icon-start" />
            New Category
          </div>
        </WppButton>

        <div className={`inner rounded-lg ${selectedCategory ? ' bg-[#F8F9FB] p-3 ' : ''}  `}>
          {selectedCategory && (
            <>
              <div className="flex flex-wrap" id={selectedCategory?.id}>
                <div className="flex justify-between w-full">
                  <WppLabel
                    htmlFor="Category title"
                    config={{
                      text: 'Category',
                    }}
                  />
                  <DeleteButton handleOnClick={() => handleDelete(selectedCategory?.id)} />
                </div>
                <WppInput
                  name="Category title"
                  placeholder="Type Category Title"
                  value={selectedCategory?.name}
                  onWppChange={handleCategoryChange}
                  required
                  className="w-full mb-8"
                />
              </div>

              <div className="subcategory-title pl-10 mb-5 justify-between flex">
                <WppTypography className="text-[#4D5358]" type="m-strong">
                  Subcategories
                </WppTypography>
              </div>
            </>
          )}
          {selectedCategory?.subcategories && (
            <div className="subcategory-list pl-10">
              {selectedCategory.subcategories.map(subcategory => {
                return (
                  <div key={subcategory.id} className="pt-4" id={subcategory.id}>
                    <div className="flex justify-between mb-4">
                      <WppInput
                        className="flex-grow"
                        placeholder="Type Subcategory Title"
                        value={subcategory.name}
                        onWppChange={e => handleSubcategoryTitleChange(e, subcategory.id)}
                      />
                      <DeleteButton handleOnClick={() => handleDelete(subcategory?.id)} />
                    </div>
                    <div className="flex justify-between mb-8 w-full">
                      <WppRichtext
                        className="w-full"
                        placeholder="Type Subcategory Description"
                        value={subcategory.instruction}
                        onBlur={e => handleInstructionQueryCache(e, subcategory.id)}
                        onMouseLeave={e => handleInstructionQueryCache(e, subcategory.id)}
                        onWppChange={e => handleInstructionChange(e, subcategory.id)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          {selectedCategory && (
            <div className="flex w-full justify-end gap-1" data-tesid="AgencyEditKB-subcat-button">
              <WppActionButton onClick={handleAddSubcategory}>
                <WppIconPlus /> <span className="ml-1">Add Subcategory</span>
              </WppActionButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
