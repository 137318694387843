import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppIconAdd,
  WppIconButton,
  WppIconDataViewCards,
  WppIconDataViewList,
  WppIconReset,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppSideModal,
  WppSortButton,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { UseCasesFilterGroupProps } from './types'
import style from './useCasesFilterGroup.module.scss'
import { UseCasesEditAdd } from '../useCasesEditAdd/UseCasesEditAdd'

export const UseCasesFilterGroup = ({
  handleToggle,
  toggleView,
  handleSearchChange,
  filters = '',
  filterCount = 0,
  handleResetFilters,
  handleApplyFilters,
  handleSortFilter,
  handleCancelFilters,
  resetFiltersVisible,
  disabled,
  isAsc,
  activeSortType,
  hideSorts = false,
  searchValue,
}: UseCasesFilterGroupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<'filters' | 'add' | ''>('')

  const onSearchChange = (e: WppInputCustomEvent<InputChangeEventDetail>) => handleSearchChange(e?.detail?.value || '')

  const handleOpenModal = (modal: 'filters' | 'add') => {
    setIsModalOpen(true)
    setModalContent(modal)
  }
  const handleCloseModal = () => setIsModalOpen(false)

  const handleReset = () => {
    handleResetFilters()
  }
  const handleApply = () => {
    handleApplyFilters()
    handleCloseModal()
  }

  const handleCancel = () => {
    handleCloseModal()
    handleCancelFilters()
  }

  return (
    <div className="flex mb-4 justify-between">
      <div className="flex gap-4 w-1/2">
        <WppInput
          disabled={disabled}
          size="s"
          placeholder="Search"
          aria-label="Search use cases"
          id="search-input"
          onWppChange={onSearchChange}
          value={searchValue}
        />
        <WppFilterButton
          name="Filters"
          onClick={() => handleOpenModal('filters')}
          counter={filterCount}
          disabled={disabled}
        >
          Filters
        </WppFilterButton>
        <WppSideModal size="m" open={isModalOpen} onWppSideModalClose={handleCancel}>
          {filters && modalContent === 'filters' && (
            <>
              <h3 slot="header">Filter</h3>
              <div slot="body" className="flex flex-col gap-6">
                {filters}
              </div>
              <div slot="actions" className="flex justify-between">
                {resetFiltersVisible && (
                  <WppActionButton variant="primary" onClick={handleReset}>
                    Reset <WppIconReset slot="icon-start" />
                  </WppActionButton>
                )}
                <WppButton variant="secondary" size="s" onClick={handleCancel} className="ml-auto mr-3">
                  Cancel
                </WppButton>
                <WppButton variant="primary" size="s" onClick={handleApply}>
                  Apply
                </WppButton>
              </div>
            </>
          )}
          {modalContent === 'add' && (
            <>
              <h3 slot="header">Add Use Case</h3>
              <div slot="body" className="flex flex-col gap-6">
                <UseCasesEditAdd handleSave={handleCloseModal} handleCancel={handleCloseModal} />
              </div>
            </>
          )}
        </WppSideModal>
      </div>

      <div className="flex  ml-auto">
        {!hideSorts && (
          <WppMenuContext className="mr-4">
            <WppSortButton slot="trigger-element" name="Sorts" disabled={disabled}>
              Sort
            </WppSortButton>
            <div>
              <WppListItem onWppChangeListItem={() => handleSortFilter('title')}>
                <p slot="label">Use Case Name {activeSortType === 'title' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('client')}>
                <p slot="label">Client {activeSortType === 'client' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('subCategory')}>
                <p slot="label">Subcategory {activeSortType === 'subCategory' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('isConfidential')}>
                <p slot="label">Confidentiality</p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('createdAt')}>
                <p slot="label">
                  Date Created
                  {activeSortType === 'createdAt' && !isAsc ? ' (Old-New)' : ' (New-Old)'}
                </p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('createdBy')}>
                <p slot="label">Added By {activeSortType === 'createdBy' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
              </WppListItem>
            </div>
          </WppMenuContext>
        )}

        <WppIconButton
          size="s"
          className={clsx(style.lButton, { [style.selected]: toggleView === 'grid' })}
          onClick={() => handleToggle('grid')}
          disabled={disabled}
        >
          <WppIconDataViewCards />
        </WppIconButton>
        <WppIconButton
          className={clsx(style.rButton, { [style.selected]: toggleView === 'list' })}
          size="s"
          onClick={() => handleToggle('list')}
          disabled={disabled}
        >
          <WppIconDataViewList />
        </WppIconButton>

        <WppButton
          className="ml-4"
          variant="primary"
          onClick={() => handleOpenModal('add')}
          size="s"
          disabled={disabled}
        >
          <WppIconAdd slot="icon-start" />
          New Use Case
        </WppButton>
      </div>
    </div>
  )
}
