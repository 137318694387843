import { SelectChangeEventDetail } from '@wppopen/components-library'
import { WppListItem, WppModal, WppSelect, WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ApiPaginator } from 'components/ApiPaginator'
import EmptyState from 'components/EmptyState'
import { ProjectsFilterGroup } from 'components/ProjectsFilterGroup'
import { sortType } from 'components/ProjectsFilterGroup/types'
import { ProjectContext } from 'contexts/Project'
import { useApiSearch } from 'hooks/useApiSearch'
import { useApiSortFilters } from 'hooks/useApiSortFilters'
import { useTranslation } from 'hooks/useTranslation'
import { useGetMarketsByIds } from 'utils/projectUtils'
import { NO_RESULTS_TITLE, NO_RESULTS_DESCRIPTION } from 'utils/translations'

import { ProjectDeleteModal } from './ProjectDeleteModal'
import ProjectPreviewCard from './ProjectPreviewCard'
import { IProjectDashboardView } from './types'
import { FetchRfiFiltersParams } from '@/types/rfis/rfi'

export enum ControlValues {
  ALL = 'All',
  MY_PROJECTS = 'My projects',
  SHARED_WITH_ME = 'Shared with me',
}

export const ProjectDashboardView = ({
  navigate,
  paginator,
  projects,
  isLoading,
  isFetching,
  error,
  agencyPermission,
}: IProjectDashboardView) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const getTranslation = useTranslation()
  const {
    state: { rfiMeta },
  } = useContext(ProjectContext)

  const user = useOs()
  const { email } = user?.osContext?.userDetails
  const [searchUrlParamName, setSearchTerm] = useApiSearch('projectName', isFetching)

  const [searchValue, setSearchValue] = useState<string>(searchParams.get(searchUrlParamName) ?? '')
  const [isDefaultState, setIsDefaultState] = useState<boolean>(true)
  const [numActiveFilters, setNumActiveFilters] = useState<number>(0)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<{ id: string; name: string }>({ id: '', name: '' })

  const [clientNamesSelectValue, setClientNamesSelectValue] = useState<string[]>([])
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>([])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>([])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>([])
  const [createBySelectValues, setCreateBySelectValues] = useState<string[]>([])

  const [updatedByselectValues, setupdatedByselectValues] = useState<string[]>([])

  const [controlValue, setControlValue] = useState<ControlValues>(ControlValues.ALL)

  useEffect(() => {
    setClientNamesSelectValue(
      searchParams.get('filter[clientIds]') ? searchParams.get('filter[clientIds]')!.split(',') : [],
    )
    setAgencieSelectValues(
      searchParams.get('filter[agencyIds]') ? searchParams.get('filter[agencyIds]')!.split(',') : [],
    )
    setPitchTypeSelectValues(
      searchParams.get('filter[pitchTypeIds]') ? searchParams.get('filter[pitchTypeIds]')!.split(',') : [],
    )
    setMarketsSelectValues(
      searchParams.get('filter[marketIds]') ? searchParams.get('filter[marketIds]')!.split(',') : [],
    )
    setCreateBySelectValues(
      searchParams.get('filter[createdBys]') && !searchParams.get('filter[createdBys]')?.includes(encodeURI(email))
        ? searchParams.get('filter[createdBys]')!.split(',')
        : [],
    )
    setupdatedByselectValues(
      searchParams.get('filter[updatedBys]') ? searchParams.get('filter[updatedBys]')!.split(',') : [],
    )
    const controlValue =
      searchParams.get('filter[sharedWithMe]') ||
      (searchParams.get('filter[createdBys]') && searchParams.get('filter[createdBys]') === encodeURI(email))
        ? searchParams.get('filter[sharedWithMe]')
          ? ControlValues.SHARED_WITH_ME
          : ControlValues.MY_PROJECTS
        : ControlValues.ALL
    setControlValue(controlValue)
  }, [searchParams, email])

  const [controlValueApplied, setControlValueApplied] = useState<ControlValues>(controlValue)

  const calculateFilters = useCallback((): FetchRfiFiltersParams => {
    let activeFilters: FetchRfiFiltersParams = {}
    activeFilters = {
      ...activeFilters,
      'filter[marketIds]': marketsSelectValues.length > 0 ? marketsSelectValues.join(',') : null,
      'filter[clientIds]': clientNamesSelectValue.length > 0 ? clientNamesSelectValue.join(',') : null,
      'filter[pitchTypeIds]': pitchTypeSelectValues.length > 0 ? pitchTypeSelectValues.join(',') : null,
      'filter[agencyIds]': agencieSelectValues.length > 0 ? agencieSelectValues.join(',') : null,
      'filter[createdBys]': createBySelectValues.length > 0 ? createBySelectValues.join(',') : null,
      'filter[updatedBys]': updatedByselectValues.length > 0 ? updatedByselectValues.join(',') : null,
    }

    if (controlValue === ControlValues.MY_PROJECTS) {
      activeFilters = {
        ...activeFilters,
        'filter[createdBys]': email,
        'filter[sharedWithMe]': null,
      }
    } else if (controlValue === ControlValues.SHARED_WITH_ME) {
      activeFilters = {
        ...activeFilters,
        'filter[sharedWithMe]': 'true',
        'filter[createdBys]': null,
      }
    } else {
      if (createBySelectValues.length === 0) {
        activeFilters = {
          ...activeFilters,
          'filter[sharedWithMe]': null,
          'filter[createdBys]': null,
        }
      }
    }
    return activeFilters
  }, [
    clientNamesSelectValue,
    controlValue,
    createBySelectValues,
    marketsSelectValues,
    pitchTypeSelectValues,
    updatedByselectValues,
    agencieSelectValues,
    email,
  ])

  const [activeFilters, setActiveFilters] = useState<FetchRfiFiltersParams>(calculateFilters())

  const filterGroupContainerRef = useRef<HTMLDivElement>(null)
  const [{ sortAsc, activeSortType }, handleSortFilters] = useApiSortFilters<sortType>('createdAt', false)

  const { clients, markets: projectsMarkets, pitches, createdBys, agencies } = rfiMeta

  const marketIds = [...new Set(projectsMarkets?.map(market => market.id) || [])]

  const markets = useGetMarketsByIds(marketIds, true)

  const handleResetFilters = () => {
    setControlValue(ControlValues.ALL)
    handleCancelFilters(true)
    setTimeout(() => {
      setIsDefaultState(true)
    }, 10)
  }

  const handleApplyFilters = () => {
    setControlValueApplied(controlValue)
    applyAllFilters(calculateFilters())
  }

  const applyAllFilters = (activeFilters: FetchRfiFiltersParams) => {
    setTimeout(() => {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
      setActiveFilters(activeFilters)
    }, 0)
  }

  useEffect(() => {
    for (const [key, value] of Object.entries(activeFilters)) {
      setSearchParams(prev => {
        if (value) {
          prev.set(key, value)
        } else {
          prev.delete(key)
        }
        return prev
      })
    }

    const numFilters = Object.values(activeFilters).filter(value => value !== null).length
    setNumActiveFilters(numFilters)
    setIsDefaultState(numFilters === 0)
  }, [activeFilters, setSearchParams])

  const handleCancelFilters = (reset = false) => {
    if (reset) {
      setMarketsSelectValues([])
      setClientNamesSelectValue([])
      setPitchTypeSelectValues([])
      setCreateBySelectValues([])
      setupdatedByselectValues([])
      setAgencieSelectValues([])
    } else {
      setControlValue(controlValueApplied)
      setMarketsSelectValues(activeFilters['filter[marketIds]']?.split(',') || [])
      setClientNamesSelectValue(activeFilters['filter[clientIds]']?.split(',') || [])
      setPitchTypeSelectValues(activeFilters['filter[pitchTypeIds]']?.split(',') || [])
      setCreateBySelectValues(activeFilters['filter[createdBys]']?.split(',') || [])
      setupdatedByselectValues(activeFilters['filter[updatedBys]']?.split(',') || [])
      setAgencieSelectValues(activeFilters['filter[agencyIds]']?.split(',') || [])
    }
  }

  /* Search */
  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value)
      setSearchTerm(value)
    },
    [setSearchTerm],
  )

  const handleAgencySelectChange = useCallback((e: CustomEvent<SelectChangeEventDetail>) => {
    setAgencieSelectValues(e.detail.value)
  }, [])

  const handleSortDisplayData = useCallback(
    (type: sortType) => {
      handleSortFilters(type)
    },
    [handleSortFilters],
  )

  const isEmpty = projects.length === 0 && !error && !isFetching && (numActiveFilters > 0 || searchValue.length > 0)

  return (
    <div className="px-4">
      <div className="flex justify-start mb-4">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
      </div>
      <div className="flex justify-center mb-4" ref={filterGroupContainerRef}>
        <ProjectsFilterGroup
          navigate={navigate}
          agencyPermission={agencyPermission}
          disabled={isLoading || isFetching}
          handleApplyFilters={handleApplyFilters}
          handleResetFilters={handleResetFilters}
          handleCancelFilters={handleCancelFilters}
          handleSearchChange={handleSearchChange}
          controlValue={controlValue}
          setFilterControlValue={setControlValue}
          numOfSelectedFilters={numActiveFilters}
          resetFiltersVisible={!isDefaultState}
          handleSortFilter={handleSortDisplayData}
          activeSortType={activeSortType}
          isAsc={sortAsc}
          searchValue={searchValue}
          agencyDropdown={<></>}
          filters={
            <>
              <WppSelect
                aria-label="Agencies"
                title="Agencies"
                placeholder="Select Agencies"
                type="multiple"
                labelConfig={{
                  text: 'Agencies',
                }}
                withSearch
                withFolder
                value={agencieSelectValues}
                onWppChange={handleAgencySelectChange}
              >
                {agencies?.map(item => (
                  <WppListItem key={`${item.id}-agencies`} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Markets"
                title="Markets"
                placeholder="Select markets"
                type="multiple"
                labelConfig={{
                  text: 'Markets',
                }}
                withSearch
                withFolder
                onWppChange={e => {
                  setMarketsSelectValues(e.detail.value)
                }}
                value={marketsSelectValues}
              >
                {(markets as { market: string; id: string }[])?.map(item => (
                  <WppListItem key={`${item.id}-markets`} value={item.id}>
                    <p slot="label">{item.market}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Clients"
                title="Clients"
                placeholder="Select clients"
                type="multiple"
                labelConfig={{
                  text: 'Clients',
                }}
                withFolder
                withSearch
                value={clientNamesSelectValue}
                onWppChange={e => {
                  const clientValues = e.detail.value.reduce(
                    (accu: string[], item: { name: string; id: string } | string) => {
                      if (typeof item === 'string') {
                        accu.push(item)
                      }
                      return accu
                    },
                    [],
                  )
                  setClientNamesSelectValue(clientValues)
                }}
              >
                {clients?.map(item => (
                  <WppListItem key={`${item.id}-clients`} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Pitch type"
                title="Pitch type"
                placeholder="Select pitch type"
                type="multiple"
                labelConfig={{
                  text: 'Pitch type',
                }}
                withSearch
                value={pitchTypeSelectValues}
                withFolder
                onWppChange={e => {
                  setPitchTypeSelectValues(e.detail.value)
                }}
              >
                {pitches?.map(item => (
                  <WppListItem key={`${item.id}-pitches`} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Created by"
                title="Created by"
                placeholder="Select created by"
                type="multiple"
                labelConfig={{
                  text: 'Created by',
                }}
                withSearch
                withFolder
                value={createBySelectValues}
                onWppChange={e => {
                  setCreateBySelectValues(e.detail.value)
                }}
                dropdownConfig={{ placement: 'top' }}
              >
                {createdBys?.map(item => (
                  <WppListItem key={item.email} value={item.email}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </>
          }
        />
      </div>
      {!error && isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}

      {!error &&
        !isFetching &&
        (isEmpty ? (
          <EmptyState title={getTranslation(NO_RESULTS_TITLE)} description={getTranslation(NO_RESULTS_DESCRIPTION)} />
        ) : (
          <>
            <div className={`${true ? '' : 'pb-16'} grid grid-cols-3 gap-6 relative`}>
              {projects.map((project: any) => (
                <ProjectPreviewCard
                  key={project.id}
                  {...project}
                  handleSelectedProject={project => {
                    setIsEditModalOpen(true)
                    setSelectedProject(project)
                  }}
                  closeDeleteModal={() => setIsEditModalOpen(false)}
                />
              ))}
              {projects.length === 0 && !error && !isFetching && !isLoading && 'No results found'}
            </div>
            <ApiPaginator paginator={paginator} />
          </>
        ))}

      <WppModal
        open={isEditModalOpen}
        onWppModalCloseStart={() => setIsEditModalOpen(false)}
        size="s"
        disableOutsideClick
      >
        <ProjectDeleteModal
          projectId={selectedProject?.id || ''}
          name={selectedProject?.name || ''}
          handleModalClose={() => {
            setIsEditModalOpen(false)
          }}
        />
      </WppModal>
    </div>
  )
}
